import React from 'react';
import { cn } from '@bem-react/classname';
import { FormattedMessage } from "react-intl"

import './Youtube.scss';

const Youtube = () => {
    const classItem = cn('Youtube');

    return (
        <a href="https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA" target="_blank" rel="noreferrer" className={classItem()}>
            <h3 className={classItem('Title')}>
                <FormattedMessage id="youtube_title" />
            </h3>
            <p className={classItem('Description')}>
                <FormattedMessage id="youtube_description" />
            </p>
            <span className={classItem('PlayIcon')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59">
                    <path id="Polygon_3" data-name="Polygon 3" d="M29.5,0,59,59H0Z" transform="translate(59) rotate(90)" fill="#fc0d1b"/>
                </svg>
                <span>
                    <FormattedMessage id="youtube_watch" />
                </span>
            </span>
        </a>
    )
};

export default Youtube;