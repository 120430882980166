import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import React from 'react';
import Marquee from "react-fast-marquee";
import Wrapper from '../Wrapper';
import './Section.scss';
import {MarqueeHeader} from "../MarqueeHeader/MarqueeHeader";


const Section = ({ className, title, leftColumn, rightColumn }) => {
    const classItem = cn('Section');

    return (
        <div className={classnames(classItem(), className)}>
            <MarqueeHeader title={title}/>
            <Wrapper>
                <div className={classItem('Container')}>
                    <div className={classItem('Content', { 'left': true })}>
                        {leftColumn}
                    </div>
                    <div className={classItem('Content', { 'right': true })}>
                        {rightColumn}
                    </div>
                </div>
            </Wrapper>
        </div>
    )
};

export default Section;
