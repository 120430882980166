import React from 'react';
import { cn } from '@bem-react/classname';

import './SectionContent.scss';

const SectionContent = ({ title, description, actions }) => {
    const classItem = cn('SectionContent');
    return (
        <div className={classItem()}>
            <h2 className={classItem('Title')} dangerouslySetInnerHTML={{__html: title}}></h2>
            <p className={classItem('Description')}>{ description }</p>
            { actions && 
                <div className={classItem('Actions')}>
                    { actions }
                </div>
            }
        </div>
    )
};

export default SectionContent;