import React from 'react';
import { cn } from '@bem-react/classname';

import { FormattedMessage } from "react-intl"

import './WatchButton.scss';


// Watch how it works
const WatchButton = ({ href }) => {
    const classItem = cn('WatchButton');

    return (
        <a className={classItem()} href={href} target="_blank" rel="noreferrer">
            <FormattedMessage id="showVideo" />
        </a>
    )
};

export default WatchButton;