import React from 'react';
import { cn } from '@bem-react/classname';
import { FormattedMessage } from "react-intl"

import './MainSectionActions.scss';

const MainSectionActions = () => {
    const classItem = cn('MainSectionActions');

    return (
        <div className={classItem()}>
            <a className={classItem('Button', {'primary': true})} target="_blank" href="https://wizard.viewst.com/signup?lang=en" rel="noreferrer">
                <FormattedMessage id="start"/>
            </a>
            <a className={classItem('Button', {'secondary': true})} target="_blank" href="https://calendly.com/callvd" rel="noreferrer">
                <FormattedMessage id="demo"/>
            </a>
        </div>
    )
};

export default MainSectionActions;