import React, { useCallback, useLayoutEffect, useState } from "react";
import { cn } from "@bem-react/classname";
import Marquee from "react-fast-marquee";
import "./MarqueeHeader.scss";

export const MarqueeHeader = ({ title }) => {
  const classItem = cn("MarqueeHeader");

  const generateTitle = useCallback((title) => {
    let tmpTitle = title;

    for (let i = 0; i < 15; i++) {
      tmpTitle += `&nbsp;${title}`;
    }

    return tmpTitle;
  }, []);

  const [hideH1, setHideH1] = useState(false);

  useLayoutEffect(() => {
    setHideH1(false)
  }, [])

  return (
    <div className={classItem()}>
      {!hideH1 && <Marquee speed={50} gradient={false} direction="left">
        <h1 className={classItem("Title")} dangerouslySetInnerHTML={{ __html: generateTitle(title) }}></h1>
      </Marquee>}
      {hideH1 && (
        <h1 className={classItem("Title")} dangerouslySetInnerHTML={{ __html: generateTitle(title) }}></h1>
      )}
    </div>);
};