import React from 'react';
import { cn } from '@bem-react/classname';
import Wrapper from '../Wrapper';
import { FormattedMessage } from "react-intl"

import './TrustedBy.scss';

const TrustedBy = () => {
    const classItem = cn('TrustedBy');
    
    return (
        <div className={classItem()}>
            <div className={classItem('Wrapper')}>
                <Wrapper>
                    <div className={classItem('Container')}>
                        <h2 className={classItem('Title')}>
                            <FormattedMessage id="trustedBy" />
                        </h2>
                        <div className={classItem('Content')}>
                            {/* <MarqueeHeader> */}
                                <img width="1380" height="350"  className={classItem('Logo')} src={"/images/trusted-by/rocket10.png"} alt="rocket10" />
                                <img width="1418" height="261" className={classItem('Logo')} src={"/images/trusted-by/bidebase.png"} alt="bidibase" />
                                <img width="2000" height="512" className={classItem('Logo')} src={"/images/trusted-by/nextlevel.png"} alt="NEXTLevel" />
                                <img width="247" height="44" className={classItem('Logo')} src={"/images/trusted-by/stationf.svg"} alt="STATIONF" />
                                <img width="1060" height="320" className={classItem('Logo')} src={"/images/trusted-by/ventureout.png"} alt="Venture out" />
                                <img width="1060" height="320" className={classItem('Logo')} src={"/images/trusted-by/av_white_bg@2x.png"} alt="av" />
                                <img width="1060" height="320" className={classItem('Logo')} src={"/images/trusted-by/havasgroup-red@2x.png"} alt="Venture out" />
                            {/* </MarqueeHeader> */}
                        </div>
                    </div>
                </Wrapper>
            </div>
        </div>
    )
}

export default TrustedBy;