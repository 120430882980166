import { useIntl } from "gatsby-plugin-react-intl";
import React, { useState } from "react";
import Help from "../components/Help";
import HorizontalSection from "../components/HorizontalSection";
import MainSectionActions from "../components/MainSectionActions";
import ReviewsSlider from "../components/ReviewsSlider";
import Section from "../components/Section";
import SectionContent from "../components/SectionContent";
import TrustedBy from "../components/TrustedBy";
import VariableContentCarousel from "../components/VariableContentCarousel/VariableContentCarousel";
import CollaborationContentCarousel from "../components/VariableContentCarousel/CollaborationContentCarousel";
import WatchButton from "../components/WatchButton";
import Youtube from "../components/Youtube";
import { useMediaQuery } from "../landingnewcomponents/Flow";
import Layout from "../layouts/layout";
import "./../common/styles.scss";


const IndexPage = (props) => {
  const [withDots, setWithDots] = useState(false);

  useMediaQuery("(min-width: 0) and (max-width: 1023px)", (matches) => {
    setWithDots(matches);
  });
  const { formatMessage } = useIntl();

  return (
    <Layout location={props.location}>
      <Section
        className="SectionMain"
        title={formatMessage({
          id: "pages.index.sections.main.title"
        })}
        leftColumn={
          <SectionContent
            title={formatMessage({
              id: "pages.index.sections.main.subtitle"
            })
            }
            description={formatMessage({
              id: "pages.index.sections.main.description"
            })}
            actions={< MainSectionActions />}
          />
        }
        rightColumn={
          <video poster={"/images/poster.png"} autoPlay={true} muted={true} playsInline loop={true} controls={false}
                 style={{ width: "100%", height: "auto" }}>
            <source src="/videos/promo.mp4" type="video/mp4" />
          </video>
        }
      >
      </Section>
      <TrustedBy />
      <Section
        className="SectionDesignAds"
        title={
          formatMessage({
            id: "pages.index.sections.designAds.title",
            defaultMessage: "Design Ads <b>In Minutes</b>"
          })}
        leftColumn={
          < SectionContent
            title={
              formatMessage({
                id: "pages.index.sections.designAds.subtitle"
              })
            }
            description={
              formatMessage({
                id: "pages.index.sections.designAds.description"
              })}
            actions={< WatchButton href={"https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA"} />}
          />
        }
        rightColumn={
          <div className="picture">
            <img loading="lazy" src="/images/design-in-minutes@2x.png" alt="" />
          </div>
        }
      />
      < Section
        className="SectionVariableContent"
        title={
          formatMessage({
            id: "pages.index.sections.variableContent.title",
            defaultMessage: "Variable content on banners & <b>Boost your Ads</b>"
          })}
        leftColumn={
          <VariableContentCarousel />
        }
        rightColumn={
          < SectionContent
            title={
              formatMessage({
                id: "pages.index.sections.variableContent.subtitle",
                defaultMessage: "Automations ⚡️"
              })}
            description={
              formatMessage({
                id: "pages.index.sections.variableContent.description",
                defaultMessage: "Built in automations to help speed up ad production."
              })}
            actions={< WatchButton href={"https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA"} />}
          />
        }
      />
      <Section
        className="SectionScaleWork"
        title={
          formatMessage({
            id: "pages.index.sections.scaleWork.title",
            defaultMessage: "<b>Work together</b> at Scale"
          })}
        leftColumn={
          <SectionContent
            title={
              formatMessage({
                id: "pages.index.sections.scaleWork.subtitle",
                defaultMessage: "Collaboration 🤝"
              })}
            description={
              formatMessage({
                id: "pages.index.sections.scaleWork.description",
                defaultMessage: "Bringing everyone together in one workspace to hit deadlines."
              })}
            actions={< WatchButton href={"https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA"} />}
          />
        }
        rightColumn={
          <CollaborationContentCarousel />
        }
      />
      < HorizontalSection
        title={
          formatMessage({
            id: "pages.index.sections.adBooster.title",
            defaultMessage: "Viewst Ad boosters"
          })}
        subtitle={`<a href="http://viewstadboosters.slack.com" target="_blank" rel="noreferrer">${formatMessage({
          id: "pages.index.sections.adBooster.subtitle",
          defaultMessage: "Join the community"
        })}</a >`}
      >
        <ReviewsSlider dots={withDots} />
      </HorizontalSection>
      <HorizontalSection
        title={formatMessage({
          id: "pages.index.sections.toBoost.title",
          defaultMessage: "Viewst to boost"
        })}
        subtitle={
          formatMessage({
            id: "pages.index.sections.toBoost.subtitle",
            defaultMessage: "Learning center"
          })}
      >
        <Youtube />
      </HorizontalSection>
      <HorizontalSection
        title={formatMessage({
          id: "pages.index.sections.toBoost.help",
          defaultMessage: "Help"
        })}
      >
        <Help />
      </HorizontalSection>
    </Layout>
  );
};

export default IndexPage;
