import React from 'react';
import { cn } from '@bem-react/classname';
import Wrapper from '../Wrapper';

import './HorizontalSection.scss';

const HorizontalSection = ({ title, subtitle, children }) => {
    const classItem = cn('HorizontalSection');
    
    return (
        <section className={classItem()}>
            <Wrapper>
                <h2 className={classItem('Title')}>{ title }</h2>
                {subtitle && <p className={classItem('Subtitle')} dangerouslySetInnerHTML={{ __html: subtitle }}></p>}
                <div className={classItem('Content')}>
                    { children } 
                </div>
            </Wrapper>
        </section>
    )
};

export default HorizontalSection;