import React from 'react';
import {cn} from '@bem-react/classname';
import Article from './components/Article';
import {injectIntl} from "react-intl"
import './Help.scss';

const Help = ({intl}) => {
    const classItem = cn('Help');

    return (
        <div className={classItem()}>
            {intl.locale === 'ru' &&
            <>
                <Article
                    link="/как-создать-проект-с-анимированным-ба/"
                    title="Как создать проект с анимированным баннером"
                    description="Создайте новый проект, нажав на кнопку «Новый проект» в гале..."
                />
                <Article
                    link="/командный-доступ/"
                    title="Командный доступ"
                    description="Для создания командного доступа: 1. Откройте в меню вкладку «Команда» 2. Нажмите на кнопку «Нов..."
                />
                <Article
                    link="/html-для-рекламных-сетей/"
                    title="HTML для рекламных сетей"
                    description="Сохранение в HTML5 доступно только для Дисплей баннеров. Чтобы загрузить весь проект в HTML5..."
                />
            </>
            }
            {intl.locale === 'en' &&
            <>
                <Article
                    link="/quick-tour/"
                    title="Quick tour"
                    description="Create a New creative by clicking on the “New creative” button in the projects gallery or in the..."
                />
                <Article
                    link="/team-access/"
                    title="Team access"
                    description="You and your teammates can work on the same projects by creating a Team. Please, note: Teams is not..."
                />
                <Article
                    link="/html-for-ad-networks/"
                    title="HTML for ad networks"
                    description="Saving in HTML5 available only for Display banner projects. To download the whole project in HTML5:..."
                />
            </>
            }
        </div>
    )
};

export default injectIntl(Help);