import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import { cn } from "@bem-react/classname";
import SliderPaginationDot from "./components/SliderPaginationDot";

import "./VariableContentCarousel.scss";

const VariableContentCarousel = () => {
  const classItem = cn("VariableContentCarousel");
  const settings = {
    customPaging: function(i) {
      return (
        <button aria-label={`Slide ${i}`}>
          <SliderPaginationDot />
        </button>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <div className={classItem()}>
      <div className={classItem("Slider")}>
        <Slider {...settings}>
          <div className={classItem("Slide")}>
            <img alt={"teams collaboration"}
                 style={{ width: "100%", height: "auto" }} src="/videos/team_1x.png" />
          </div>
          <div className={classItem("Slide")}>
            <video autoPlay={true} muted={true} loop={true} playsInline controls={false}
                   style={{ width: "100%", height: "auto" }}>
              <source src="/videos/1x_web_preview_link.mp4" type="video/mp4" />
            </video>
          </div>
        </Slider>
      </div>
      <div className={classItem("Controls")}>

      </div>
    </div>
  );
};

export default VariableContentCarousel;