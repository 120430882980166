import React from 'react';
import { cn } from '@bem-react/classname';

import './SliderPaginationDot.scss';

const SliderPaginationDot = ({ iterator }) => {
    const classItem = cn('SliderPaginationDot');
    return (
        <div className={classItem()}></div>
    )
};

export default SliderPaginationDot;