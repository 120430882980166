import React from 'react';
import { cn } from '@bem-react/classname';
import './Article.scss';

const Article = ({ link, title, description }) => {
    const classItem = cn('Article');
    return (
        <a href={link} className={classItem()}>
            <article className={classItem('Content')}>
                <h3 className={classItem('Title')}>{title}</h3>
                <p className={classItem('Description')}>{description}</p>
            </article>
        </a>
    )
};

export default Article;